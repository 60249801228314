import { RenderClientSide } from '@components/RenderClientSide/RenderClientSide';
import { Footer as FooterType } from '@graphql/generated-contentful/graphql';
import { FooterCurrencySelector } from './FooterCurrencySelector';
import { FooterFlexRow } from './FooterFlexRow';
import { FooterFootnote } from './FooterFootnote';
import PrivacyOptionsIcon from './PrivacyOptionsIcon';

type Props = { footerData: FooterType };

export const FooterLegal = ({ footerData }: Props) => {
  const currencyAriaLabel = footerData?.currency || 'Currency';

  return (
    <div className="pt-4">
      <FooterFlexRow>
        <FooterFootnote>
          {footerData?.legalStatements?.map((statement) => {
            return <div key={statement}>{statement}</div>;
          })}
        </FooterFootnote>
      </FooterFlexRow>
      <FooterFlexRow>
        {footerData?.legalLinksCollection && (
          <FooterFootnote>
            {footerData?.legalLinksCollection?.items.map((link) => {
              return (
                <a
                  key={link?.value}
                  href={link?.url!}
                  className="cursor-pointer text-blue"
                  target="_blank"
                >
                  {link?.value}
                </a>
              );
            })}
            <div className="flex items-center gap-1">
              <PrivacyOptionsIcon />
              <button
                id="onetrust-button"
                className="ot-sdk-show-settings text-blue"
              >
                Cookie Settings
              </button>
            </div>
          </FooterFootnote>
        )}
        <div className="ml-auto">
          <RenderClientSide>
            <FooterCurrencySelector ariaLabel={currencyAriaLabel} />
          </RenderClientSide>
        </div>
      </FooterFlexRow>
    </div>
  );
};
