export type Organization = {
  id: string;
  name: string;
  description: string;
  company: string;
  email: string;
  slug: string;
  role: OrganizationRole;
};

export enum OrganizationRole {
  OWNER = 'owner',
  MANAGER = 'manager',
  USER = 'user',
}
