'use client';

import { HttpStatusCode } from '@lib/http/HttpStatusCode';
import { failingFetcher } from '@lib/http/fetch';
import * as Sentry from '@sentry/nextjs';
import { SWRConfig } from 'swr';

export const SWRProvider = ({ children }) => {
  return (
    <SWRConfig
      value={{
        fetcher: failingFetcher,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onError: (error) => {
          if (
            error.status !== HttpStatusCode.Forbidden &&
            error.status !== HttpStatusCode.NotFound
          ) {
            Sentry.captureException(error);
          }
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};
